import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import CartLayout from "../Layout/CartLayout"
import Cart from "./Cart"
import Search from "../Search/Search"
import AddMedicineModal from "./Order/AddMedicineModal"

import { AppContext } from "../../context/AppContext"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import { isMedicineAlreadyInCart } from "./services/cart"
import { useDiscountCoupons } from "./hooks/useDiscountCoupons"

const Order = ({ backRoute, nextRoute, location }) => {
  const data = useEpharmacyProducts()
  let coupons = useDiscountCoupons()
  coupons = coupons?.allDiscountCoupons?.nodes
  const { dispatch, state } = useContext(AppContext)

  const handleOnSelect = (item) => {
    if (isMedicineAlreadyInCart(item, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${item.productTitle} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal addToCart={addMedicineToCart} medicine={item} />
          ),
        },
      })
  }

  const addMedicineToCart = async (item, qty, discountValues) => {
    const medicineList = [...state.epharmacy.medicines]
    medicineList.push({ ...item, qty: qty, discountValues })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule} to cart`,
        color: "success",
      },
    })
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    if (location?.state?.selectedMedicine) {
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `Added ${location?.state?.selectedMedicine} to cart`,
          color: "success",
        },
      })
    }
  }, [])

  const searchComponentProps = {
    placeholder: "Search medicines (i.e. Zytiga)",
    label: "Medicine Search",
    helper: {
      message:
        "Suggested Retail Price (SRP) does not reflect medicine assistance. Discount or free goods will be applied when you add your medicine to cart.",
    },
    dataSource: {
      type: "graphql",
      data: data.allProducts.nodes,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
  }

  return (
    <CartLayout
      cartContainer={<Cart open={{ medicinesCard: true }} />}
      desktop={10}
      fullhd={8}
      isCentered
      back={{ label: "Back", link: backRoute, className: "is-hidden-desktop" }}
      next={{
        label: "Next: Upload Rx",
        link: nextRoute,
        disabled: !state?.epharmacy?.medicines.length,
        className: "is-hidden-desktop",
      }}
    >
      <div className="mt-1">
        <div className="is-hidden-mobile">
          <Search {...searchComponentProps} />
        </div>
        <div className="is-hidden-desktop is-hidden-tablet">
          <Link to="/epharmacy/search">
            <Search {...searchComponentProps} />
          </Link>
        </div>
      </div>
    </CartLayout>
  )
}

export default Order
